<template>
  <v-app>
    <div class="baseBgSet">
      <!-- 讀取畫面 -->
      <v-overlay v-show="this.$store.state.loading" style="z-index: 999">
        <!-- <h2>Data is Loading...</h2> -->
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-main>
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
    videos: [],
  }),

  mounted() {
    this.getYoutubePlayList();
  },

  methods: {
    //取得youtube 指定播放清單裡的影片路徑
    getYoutubePlayList() {
      // 指定您的播放清單ID
      const playlistId = "PLsHrRcCPxdxjSKj7GlgVzepI2E7WTXMHJ";
      const API_KEY = "AIzaSyAzuOit1ZG50Q5m6Qr0rEt-P6MP_VvDoxM";

      // 使用YouTube Data API v3獲取播放清單中的影片
      this.axios
        .get(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&key=${API_KEY}`
        )
        .then((response) => {
          // console.log(response.data.items);
          let playlists = response.data.items.map((item) => {
            return {
              title: item.snippet.title,
              url: `https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`,
              code: item.snippet.resourceId.videoId
            };
          });
          // console.log(this.videos);
          let postData = {
            database: "youtubeplaylist",
            data: playlists,
          };

          this.$store.commit("setItems", postData);
        })
        .catch((error) => {
          console.error("Error fetching playlist videos:", error);
        });
    },
  },
};
</script>
