import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "@/assets/css/styleblue.css"
import "@/assets/css/mytransition.css"

/*
導入全局使用的axios 主要的get, post, delete 都寫在api.js裡
之後各畫面用時使用this.$api再去call 相對應的動作就可以了
*/
import api from "@/assets/js/api.js";
Vue.prototype.$api = api

/*
sweetalert2 引用及設定
*/
import Swal from 'sweetalert2'

Vue.prototype.$alert = (style) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  Toast.fire(style);
}

//設定全域函數 -> 刪除時的sweetalert2顯示格式
Vue.prototype.yn = function (title = '確認刪除!?') {
  return Swal.fire({
    icon: "warning",
    title: title,
    toast: false,
    position: "center",
    showConfirmButton: true,
    showCancelButton: true,
    timerProgressBar: false,
    timer: 0,
    confirmButtonText: `確認`,
    cancelButtonText: `取消`,
    confirmButtonColor: "green",
    cancelButtonColor: "red",
    focusCancel: true,
  });
}
//sweetalert2 引用及設定end

//2022.8.16導入ckeditor5
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
